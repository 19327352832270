import React from "react"


const Awrapper = () => {
  return (
    <>
      <section className='awrapper'>

      </section>
    </>
  )
}

export default Awrapper
