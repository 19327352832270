import React, { useState } from "react";
import Head from "./Head";
import "./header.css";

const Header = () => {
  const [click, setClick] = useState(false);

  return (
    <>
      <Head />
      <header>
        <nav className='flexSB'>
          <ul className={click ? "mobile-nav active" : "flexSB"} onClick={() => setClick(false)}>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="#about">About</a>
            </li>
            <li>
              <a href="#nastava">Nastava</a>
            </li>
          </ul>
          <div className='start'>
            <a href="#footer"> {/* Use a regular anchor link */}
              <div className='button'>KONTAKT</div>
            </a>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
