import React from "react";
import Heading from "../../common/heading/Heading";
import "./Hero.css";

const Hero = () => {
  return (
    <>
      <section className='hero'>
        <div className='container'>
          <div className='row'>
            <div className='text-center'>
              <Heading subtitle='DOBRODOŠLI NA TURSKI ZA SVE' title='Najbolje Online Obrazovno Iskustvo' />
              <p>Sa dugogodišnjim iskustvom u obrazovanju i pedagogiji, <br /> pružamo online nastavu koja uključuje grupne i individualne časove.</p>
            </div>
          </div>
        </div>
      </section>
      <div className='margin'></div>
    </>
  );
}

export default Hero;
